.socialBar {
  height: 300px;
  position: absolute;
  background-color: white;
  margin-right: 5px;
  opacity: 0;
  animation: vanish 0.6s ease-in-out;
}

.memberCard:hover .socialBar {
  opacity: 1;
  width: 50px;
  animation: appear 0.6s;
}
.imageWrap {
  position: relative;
  overflow: hidden;
}

.imageStyle {
  transition: all 0.5s ease-in-out;
}

.memberCard:hover .imageStyle {
  filter: saturate(0.1%);
  transform: scale(1.06);
}

/* @keyframes appear {
    from {transform:scaleX(0)}
    to {transform: scaleX(1)}
  }

  @keyframes vanish {
    from {opacity: 1; width: 50px; transform: scaleX(1)}
    to {opacity: 1; width: 50px; transform: scaleX(0)}
  } */
