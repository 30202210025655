.joinBtn{
    border: 3px solid black;
    border-radius: 10px;
    padding: 8px 20px 8px 20px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.joinBtn:hover{
    border: 3px solid #82B6E1;
    color: whitesmoke;
    background-color: #82B6E1;
}