.featureCard {
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  transition: 0.3s ease-in-out;
}

.bgOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  backdrop-filter: blur(2.3px);
  height: 100%;
  box-shadow:  0px 0px 5px rgba(0,0,0,0.5);
  border-radius: 10px;
  background-color: white!important;
}

.icon {
 filter: grayscale(100%);
 transition: 0.6s ease-in-out; 
}

.featureCard:hover .icon{
  transform: scale(1.1);
}



.clientsScreen {
  font-family: "Barlow Condensed", sans-serif;
}

.slick-track{
    margin-bottom: 20px!important;
}

.slick-dots .slick-active{
    border: 4px solid #90caf9;
}
.slick-dots li{
    border: 4px solid gray;
}

.slick-dots li> button{
    /* display: none!important; */
    opacity: 0;
}


.slick-dots .slick-active button{
    display: none;
}