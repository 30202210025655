.answerRowHidden{ 
    font-size: 0;
    height: 0px; 
    padding: 0.2rem;
}

.answerRow{
    font-size: 22px;
    padding: 1.25rem;
    animation: appear 0.5s ease-in-out;
}

  

 
 