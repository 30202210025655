.navLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: unset;
  border: 0;
  cursor: pointer;
}


.notActive {
  font-weight: 600;
  color: black;
}
.active {
  background-color: unset;
  color: #0ea5e9;
  font-weight: 700;
  text-decoration: underline;
}

.notActive:hover {
  background-color: unset;
  font-weight: 700;
  color: black;
}

.navJoin{
  border: 3px solid #82B6E1;
  border-radius: 10px;
  padding: 8px 20px 8px 20px;
  font-weight: 600;
  background-color: #82B6E1;
  color: white;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.navJoin:hover{
  border: 3px solid black;
  color: black;
  background-color: unset;
}

.sideNavBarOn {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 50;
  margin-top: -0.75rem;
  background-color: #55b4f3;
  color: white;
  animation: animateLeft 0.5s;
}
.sideNavBarOf {
  flex-direction: column;
  position: absolute;
  margin-top: -0.75rem;
  background-color: #55b4f3;
  color: white;
  width:0vw;
  height: 100vh;
  animation: animateRight 1s ;
}

.bgClose {
  padding: 3px 10px 3px 10px;  
}

.sideNavRoute  button{
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 40px;
  list-style: none;
  padding-top: 20px;
  font-weight: 600;
  gap: 10px;
}
.sideNavRoute label{
  width: 50%;
  padding: 2px;
  margin-left: 40px;
  margin-top: 30px;
  background-color: unset;
  border: 3px solid black;
  color: black;
}


@keyframes animateLeft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes animateRight {
  from {
    width: 100vw;
  }
  to {
    width: 0px;
  }
}