
.send {
    border: 2px solid black;
    transition: 0.6s;
    background-color: white;
    color: black;
    padding: 10px 10px 10px 10px;
}

.send:hover {
    border: 2px solid #90CAF9;
    transition: 0.4s;
    background-color: #90CAF9;
    color: white;
}

.input:focus{outline: none;}
.textarea:focus{
    outline: none;
}

.contactBox{
    backdrop-filter: blur(2.3px);
    box-shadow:  0px 0px 5px rgba(0,0,0,0.5);
    border-radius: 10px;
}

.mail:hover i{
    color: skyblue;
}
.phone:hover i{
    color: skyblue;
}